// TV2DK FOOTER
@import "core";

// Footer
@include respond-to(base-inline) {
  .tc_footer{content-visibility:auto;contain-intrinsic-height:auto calc(1088 * var(--unit));}
  .tc_footer__logo{position:absolute;bottom:0;left:var(--gutter);display:block;min-width:var(--spacing-40);max-width:var(--spacing-40);border-radius:50%;
    svg{width:100%;height:100%;}
  }
  .tc_footer__menu{font-size:var(--font-14);padding-top:var(--spacing-16);padding-bottom:var(--spacing-48);
    &.tc_grid{row-gap:var(--spacing-48);}
  }
  .tc_footer__menu__item{display:flex;flex-direction:column;gap:var(--spacing-16);}
  .tc_footer__menu__item .tc_heading{font-size:var(--font-20);}
  .tc_footer__menu__item__list{list-style:none;display:flex;flex-direction:column;gap:var(--spacing-16);}
  .tc_footer__menu__item__list a{display:inline;color:var(--color-text);font-weight:var(--font-medium);text-decoration:none;}
  .tc_footer__privacy p{display:flex;flex-wrap:wrap;column-gap:var(--spacing-20);row-gap:var(--spacing-8);font-size:var(--font-12);padding:0 0 var(--spacing-8);color:var(--color-500);}
  .tc_footer__privacy__policy{text-decoration:none;color:inherit;
    &::after{content:"•";padding:0 0 0 var(--spacing-8);position:absolute;}
  }
  .tc_footer__privacy__cmp{font-size:inherit;color:inherit;text-decoration:none;}
  .tc_footer__privacy__copyright{width:100%;}
}

@include respond-to(small-inline) {
  .tc_footer{contain-intrinsic-size:auto calc(1054 * var(--unit));}
  .tc_footer__privacy{min-height:calc(125 * var(--unit));}
}

@include respond-to(medium-inline) {
  .tc_footer{contain-intrinsic-size:auto calc(609 * var(--unit));}
  .tc_footer__logo{bottom:auto;top:var(--spacing-40);}
  .tc_footer__menu{padding-top:var(--spacing-8);padding-bottom:var(--spacing-8);margin:0 calc(var(--gutter) + var(--spacing-40));}
  .tc_footer__privacy{min-height:calc(84 * var(--unit));}
  .tc_footer__privacy__copyright{position:relative;width:auto;
    &::before{content:"•";padding:0 var(--spacing-8) 0 0;position:absolute;left:calc(var(--spacing-12) * -1);}
  }
}

@include respond-to(large-inline) {
  .tc_footer{contain-intrinsic-size:auto calc(379 * var(--unit));}
  .tc_footer__privacy{min-height:calc(67 * var(--unit));}
}

@include respond-to(hover) {
  .tc_footer__menu__item__list a:hover{text-decoration:underline;}
  .tc_footer__privacy__policy,.tc_footer__privacy__cmp{
    &:hover{text-decoration:underline;}
  }
}
